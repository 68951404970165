<template>
  <div class="reimburseView">
    <detail-top 
      :tabsList="tabsList" 
      :createTime="detailInfo.createTime"
      headerTitle="查看报销详情"
      :title="`${detailInfo.sponsorName || ''}提交的报销审批申请`"
    />
    <div :class="[auditAuthority === 1 ? 'on-approval' : '', 'content-wrap']">
      <my-card name="1" title="基础信息">
        <div class="basics-wrap">
          <my-cell title="申请单号" :value="detailInfo.billNo" />
          <my-cell title="所属部门" :value="detailInfo.sponsorDep" />
          <my-cell title="所属月份" :value="detailInfo.billMonth" />
          <my-cell title="是否分摊" :value="detailInfo.shareType === 1 ? '是' : '否'" />
          <my-cell title="付款公司" :value="detailInfo.companyName" />
          <my-cell title="报销总额" :value="`￥${detailInfo.totalAmount}`" />
          <img class="status-img" :src="statusImg" />
        </div>
      </my-card>

      <my-card name="2" title="报销明细">
        <template v-slot:right>
          共计{{ detailsTotal }}条
        </template>
        <div class="details-list-wrap">
          <detail-item v-for="(item,index) in detailsList" :key="index" :detail="item" :index="index+1" />
          <div v-if="isUnfold" class="unfold-wrap" @click="handleUnfold">
            <div class="unfold">展开</div>
            <van-icon name="arrow-down" />
          </div>
        </div>
      </my-card>

      <my-card name="3" title="附件">
        <my-files-view :fileList="detailInfo.invoices"/>
      </my-card>

      <approval-process name="4" v-if="reimId" :billId="reimId" :module="2" />
    </div>
    <detail-approval-btn v-if="detailInfo.billNo && auditAuthority === 1" :billNo="detailInfo.billNo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon  } from 'vant';
import { reimburseDetail } from '@/api/reimburse'
import passImg from '@/assets/approvalPassImg.png'
import rejectImg from '@/assets/approvalRejectImg.png'

Vue.use(NavBar).use(Icon);

export default {
  name: "reimburseView",
  data() {
    return {
      reimId: '', // id
      tabsList: [
        {
          title: '基础信息',
          key: 1,
        },
        {
          title: '报销明细',
          key: 2,
        },
        {
          title: '附件',
          key: 3,
        },
        {
          title: '审批流程',
          key: 4,
        },
      ],
      detailInfo: {},
      detailsList: [],
      isUnfold: false,
      detailsTotal: 0,
      statusImg: '',
      auditAuthority: 0,
    };
  },
  components: {
    ApprovalProcess: () => import('@/components/business/approvalProcess/index.vue'),
    DetailTop: () => import('@/components/business/detailTop/index.vue'),
    MyCard: () => import('@/components/basics/MyCard.vue'),
    MyCell: () => import('@/components/basics/MyCell.vue'),
    MyFilesView: () => import('@/components/basics/MyFilesView.vue'),
    DetailItem: () => import('./components/DetailItem.vue'),
    DetailApprovalBtn: () => import('@/components/business/detailApprovalBtn/index.vue'),
  },
  mounted() {
    // 查看是否有内容
    this.reimId = this.$route.query.reimId;
    this.totalAmount = this.$route.query.totalAmount;
    this.status = this.$route.query.status;
    this.auditAuthority = this.$route.query.auditAuthority;
    if (this.reimId) {
      this.getDetailInfo()
    } else {
      console.log("打开错误");
    }
    if(this.status === 2){ // 驳回
      this.statusImg = rejectImg;
    } else if(this.status === 3){ // 通过
      this.statusImg = passImg;
    }
  },
  methods: {
    getDetailInfo() {
      reimburseDetail({reimId: this.reimId}).then(res => {
        let obj = res.resultData;
        this.detailInfo = obj;
        let list = obj.details;
        this.detailsTotal = list.length
        if(list.length > 3){
          this.detailsList = list.slice(0, 3);
          this.isUnfold = true;
        } else {
          this.detailsList = list;
        }
      })
    },
    handleUnfold(){
      this.detailsList = this.detailInfo.details;
      this.isUnfold = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.reimburseView{
  .content-wrap{
    overflow: auto;
    height: calc(100vh - 172px);
    .basics-wrap{
      position: relative;
      .status-img{
        width: 50%;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }
    }
    .details-list-wrap{
      .unfold-wrap{
        color: $gray;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .unfold{
          margin-right: 4px;
        }
      }
    }
  }
  >.on-approval{
    height: calc(100vh - 214px);
  }
}

</style>